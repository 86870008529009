<template>
	<!-- 课程详情 -->
	<div>
		<totop></totop>
		<el-breadcrumb separator-class="el-icon-arrow-right" class="courseDetail-page-bread">
			<el-breadcrumb-item><span @click="tohome">首页</span></el-breadcrumb-item>
			<el-breadcrumb-item v-for="(item,index) in catClass" :key="index">
				<span @click="tbchoose(item.cat_id,index)">{{item.name}}</span>
			</el-breadcrumb-item>
		</el-breadcrumb>
		<contact ref='child'></contact>
		<!-- 遮罩 -->
		<div v-show="this.zhezhao==true">
			<div class="courseDetail-zhezhao"></div>
			<div class="courseDetail-successBox">
				<div class="courseDetail-boxTips">
					<img src="../../../assets/img/courseDetail/success.png" style="float: left;">
					<span>报名成功</span>
				</div>
				<div class="courseDetail-boxText">恭喜你，报名成功，赶快去学习吧</div>
				<div class="courseDetail-boxBtn" @click="toLearn1()">去学习</div>
			</div>
		</div>

		<!-- 上部分 -->
		<!-- 付费内容 -->
		<div v-show="this.goodsData.is_free==0">
			<div class="courseDetail-page-all">
				<img :src="goodsData.goods_img" class="courseDetail-page-page-coursePic">
				<div class="courseDetail-page-page-rightTitle">
					<div class="course-goodname">{{goodsData.goods_name}}</div>
					<div class="courseDetail-titleRight">
						<img @click="showchat" src="../../../assets/img/courseDetail/chat.png" class="styletwo">
						<img src="../../../assets/img/courseDetail/share.png" class="styleone styletwo" @mouseenter="toShare" @mouseleave="share=false">
						<img src="../../../assets/img/courseDetail/collect.png" class="styletwo" v-if="this.is_collection==0" @click="toCollect()">
						<img src="../../../assets/img/courseDetail/collect1.png" class="styletwo" v-if="this.is_collection==1" @click="toCollect()">
					</div>
					<!-- 分享按钮 -->
					<div v-show="this.share==true">
						<div class="courseDetail-shareBox">
							<div class="courseDetail-shareBoxItem">
								<img src="../../../assets/img/courseDetail/qq.png" class="courseDetail-plateformIcon">
								<div ref="qrCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
								<div class="sharecourseDetail-boxText">QQ扫码</div>
							</div>
							<div class="courseDetail-shareBoxItem">
								<img src="../../../assets/img/courseDetail/wechat.png" class="courseDetail-plateformIcon">
								<div ref="vrCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
								<div class="sharecourseDetail-boxText">微信扫码</div>
							</div>
							<div class="courseDetail-shareBoxItem">
								<img src="../../../assets/img/courseDetail/sina.png" class="courseDetail-plateformIcon">
								<div ref="wrCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
								<div class="sharecourseDetail-boxText">微博扫码</div>
							</div>
						</div>
						<div class="courseDetail-triangle"></div>
					</div>
					<!-- 咨询按钮 -->

				</div>
				<div class="courseDetail-rightGrade">
					<span class="courseDetail-gradeText">报名人数 {{goodsData.countsignup}}</span>
					<el-divider direction="vertical"></el-divider>
					<!-- <span class="courseDetail-courseDetail-colLine1">|</span> -->
					<el-rate class="rate" v-model="goodsData.rank" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
				</div>
				<div class="courseDetail-rightPrice">
					<span>非会员价：</span>
					<span v-show="this.goodsData.is_free==0">￥</span>
					<span v-show="this.goodsData.is_free==0">{{goodsData.goods_price}}</span>
					<span v-show="this.goodsData.is_free==1">免费</span>
					<span v-show="this.goodsData.is_vip==1">会员免费</span>
				</div>
				<div class="courseDetail-rightDiscount">
					<div>活动</div>
					<div @click="getDiscount()">领取优惠券</div>
				</div>
				<!-- 优惠券窗口 -->
				<div class="courseDetail-organizeDiscount" v-show="this.discount==true">
					<!-- 机构优惠券 -->
					<div class="courseDetail-discountTitle">限时优惠，可领取该机构课程的优惠券</div>
					<div v-for="item in couponData" v-if="item.school_id!=0">
						<div class="line"></div>
						<div class="courseDetail-discountContent">
							<div class="courseDetail-contentUp">
								<span>￥{{item.discount}}</span>
								<span>{{item.coupon_name}}</span>
								<span v-show="item.is_get==0" @click="toGetCoupon(item.id)">领取</span>
								<span v-show="item.is_get==1">已领取</span>
							</div>
							<div class="courseDetail-contentDown">
								有效期至：{{item.end_time}}
							</div>
						</div>
					</div>
					<!-- 平台优惠券 -->
					<div class="courseDetail-discountTitle">限时优惠，可领取该平台课程的优惠券</div>
					<div v-for="item in couponData" v-if="item.school_id==0">
						<div class="line"></div>
						<div class="courseDetail-discountContent">
							<div class="courseDetail-contentUp">
								<span>￥{{item.discount}}</span>
								<span>{{item.coupon_name}}</span>
								<span v-show="item.is_get==0" @click="toGetCoupon(item.id)">领取</span>
								<span v-show="item.is_get==1">已领取</span>
							</div>
							<div class="courseDetail-contentDown">
								有效期至：{{item.end_time}}
							</div>
						</div>
					</div>
				</div>
				<div class="rightBtn" v-if="goodsData.status==1">
					<div class="courseDetail-rightBtn1" @click="toLearn()" v-show="this.is_show==0">立即报名</div>
					<div class="courseDetail-rightBtn2" v-show="this.is_show==1">
						<div>报名成功</div>
						<div class="courseDetail-zhezhao3"></div>
					</div>
					<a href="#mulu">
						<div class="courseDetail-rightBtn4" @click="preWatch()" v-if="is_show==0">试看</div>
						<div class="courseDetail-rightBtn4none" v-if="is_show!=0"></div>
					</a>
					<div class="courseDetail-courseDetail-rightBtn4">
						<div style="display: flex;justify-content: center;align-items: center;">
							<img src="../../../assets/img/courseDetail/buyCar.png" class="courseDetail-buyCarIcon">
							<div @click="toAdd()">购物车</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 免费 -->
		<div v-show="this.goodsData.is_free==1">
			<div class="courseDetail-page-all1">
				<div class="courseDetail-allLeft">
					<!-- 播放器遮罩 -->
					<div v-show="this.is_show==0">
						<div :class="['courseDetail-zhezhao4',isroom==2?'courseDetail-zhezhao4 heigt600':'']"></div>
					</div>
					<div class="video-out">
						<video v-if="isroom==1" controlslist="nodownload" id="video" width="100%" height="100%" src="" :poster="playerOptions.poster"
						 controls>

						</video>
						<videoPlayer v-if="isroom==2" width="100%" height="90%" :options="playerOptions"></videoPlayer>
					</div>
					<div class="courseDetail-allLeftIntro" v-if="isroom==1">
						<div class="courseDetail-introLeft">
							<div class="title">
								<span>{{goodsData.goods_name}}</span>
								<span>免费</span>
							</div>
							<div class="info">
								<span class="courseDetail-infoPeople">报名人数 {{goodsData.countsignup}}</span>
								<span class="courseDetail-colLine">|</span>
								<el-rate class="rate ratemr14" v-model="goodsData.rank" disabled show-score text-color="#ff9900" score-template="{value}"></el-rate>
								<img src="../../../assets/img/courseDetail/chat.png" class="icon" @click="showchat">
								<img src="../../../assets/img/courseDetail/share.png" class="icon" @mouseenter="toShare()" @mouseleave="share=false">
								<img src="../../../assets/img/courseDetail/collect.png" class="icon" v-if="this.is_collection==0" @click="toCollect()">
								<img src="../../../assets/img/courseDetail/collect1.png" class="icon" v-if="this.is_collection==1" @click="toCollect()">
								<img src="../../../assets/img/courseDetail/phone.png" class="icon stylethree">
								<span class="courseDetail-infoPeople" @click="toPhone()">用手机看</span>
							</div>
						</div>
						<!-- 报名按钮 -->
						<div class="courseDetail-introRight" v-show="this.is_show==0" @click="toLearn()">免费报名</div>
						<div v-show="this.is_show==1">
							<div class="courseDetail-introRight">报名成功</div>
							<div class="courseDetail-zhezhao2"></div>
						</div>
						<!-- 分享按钮 -->
						<div v-show="this.share==true">
							<div class="courseDetail-shareBox1">
								<div class="courseDetail-shareBoxItem">
									<img src="../../../assets/img/courseDetail/qq.png" class="courseDetail-plateformIcon">
									<div ref="q1rCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
									<div class="sharecourseDetail-boxText">QQ扫码</div>
								</div>
								<div class="courseDetail-shareBoxItem">
									<img src="../../../assets/img/courseDetail/wechat.png" class="courseDetail-plateformIcon">
									<div ref="v1rCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
									<div class="sharecourseDetail-boxText">微信扫码</div>
								</div>
								<div class="courseDetail-shareBoxItem">
									<img src="../../../assets/img/courseDetail/sina.png" class="courseDetail-plateformIcon">
									<div ref="w1rCodeUrl" id="qrCode" class="courseDetail-erweimaIcon"></div>
									<div class="sharecourseDetail-boxText">微博扫码</div>
								</div>
							</div>
							<div class="courseDetail-triangle1"></div>
						</div>
						<!-- 用手机看 -->
						<div v-show="this.phone==true">
							<div class="courseDetail-phoneBox">
								<div class="phonecourseDetail-boxText">扫一扫继续用手机看</div>
								<div class="courseDetail-phoneBoxCenter">
									<div class="courseDetail-phoneBoxItem">
										<div ref="qcodems" id="qrCode" class="courseDetail-erweimaIcon wxchattt"></div>
										<div class="courseDetail-itemText">
											<img src="../../../assets/img/courseDetail/wx.png" class="stylefour">
											<span>微信扫码</span>
										</div>
									</div>
									<div class="courseDetail-diviver"></div>
									<div class="courseDetail-phoneBoxItem">
										<div ref="qcodemss" id="qrCode" class="courseDetail-erweimaIcon wxchattt"></div>
										<div class="courseDetail-itemText">
											<img src="../../../assets/img/courseDetail/qq1.png" class="stylefour">
											<span>qq扫码</span>
										</div>
									</div>
								</div>
								<div class="courseDetail-phonecourseDetail-boxBtn">下载手机app</div>
							</div>
							<div class="courseDetail-triangle2"></div>
						</div>

					</div>
				</div>
				<div class="courseDetail-allRight">
					<div class="courseDetail-allRightBox">
						<el-collapse v-model="activeNames" @change="handleChange" class="courseDetail-detailTabs">
							<el-collapse-item class="courseDetail-collapseItem" v-for="(item,index) in menuData" :title="item.name" :name="item.id">
								<div v-for="(i,index) in item.child">
									<div class="courseDetail-tabsItem" @click="toWactch(i.id,i.type)">
										<div class="courseDetail-videoIcon">
											<img src="../../../assets/img/courseDetail/video.png">
										</div>
										<div class="courseDetail-tabsTitle">{{i.name}}</div>
										<div class="courseDetail-tabsContent1">
											<span v-if="i.type==1">【直播】</span>
											<span v-if="i.type==2">【视频】</span>
											<span v-if="i.type==3">【录播】</span>
											{{i.live_time}}
										</div>
									</div>
									<div class="line1" v-if="index!=(item.child.length-1)"></div>
								</div>
							</el-collapse-item>
						</el-collapse>
					</div>
				</div>
			</div>
		</div>


		<!-- 下部分 -->
		<div class="courseDetail-page-content">
			<!-- 课程详细介绍 -->
			<div class="courseDetail-page-detail">
				<!-- 菜单 -->
				<div class="courseDetail-detail-menu">
					<div v-for="(item,index) in menuList" :class="['courseDetail-menuTitle',chooseMenu==item.id?'courseDetail-menuTitleIn':'']"
					 @click="courseMenu(item.id)">{{item.name}}</div>
				</div>
				<div class="line"></div>
				<!-- 课程简介 -->
				<div v-show="this.chooseMenu==1">
					<div class="courseDetail-detailTitle">课程简介</div>
					<!-- <img src="../../../assets/img/courseDetail/organizeBg.png" class="courseDetail-detailPic"> -->
					<!-- <img src="../../../assets/img/courseDetail/organizeBg.png" class="courseDetail-detailPic"> -->
					<div v-html="goodsData.goods_des" class="courseDetail-detailIntro">
					</div>
					<div class="courseDetail-detailTitle">授课讲师</div>
					<div class="courseDetail-detaiTeacher">
						<div class="courseDetail-toallstart">
							<img src="../../../assets/img/courseDetail/ahead.png" class="courseDetail-aheadIcon" @click="toAheadItem()" v-if="goodsData.teacher.length>1">
							<img src="../../../assets/img/courseDetail/next.png" class="courseDetail-nextIcon" @click="toNextItem()" v-if="goodsData.teacher.length>1">
						</div>
						<div class="courseDetail-detailAllItems" id="scollItems">
							<div class="courseDetail-teacherItem" v-for="(item,index) in goodsData.teacher">
								<img :src="item.avatarurl" class="courseDetail-itemAvatra">
								<el-card class="courseDetail-box-card">
									<div class="teacherName">{{item.nickname}}</div>
									<div class="courseDetail-teacherIntro">{{item.info}}</div>
								</el-card>
							</div>
						</div>
					</div>
					<div class="courseDetail-detailTitle">适学人群</div>
					<div class="courseDetail-detailIntro">
						{{goodsData.suit}}
					</div>
				</div>
				<!-- 课程目录 -->
				<div v-show="this.chooseMenu==2">
					<el-collapse v-model="activeNames" @change="handleChange" class="courseDetail-detailTabs" id="mulu">
						<el-collapse-item v-for="(item,index) in menuData" :title="item.name" :name="item.id">
							<div v-for="(i,index) in item.child">
								<div class="courseDetail-tabsItem">
									<div class="courseDetail-videoIcon">
										<img src="../../../assets/img/courseDetail/video.png">
									</div>
									<div class="courseDetail-tabsTitle">{{i.name}}</div>
									<div class="courseDetail-tabsContent">{{i.live_time}}</div>
									<div class="courseDetail-tabsBtn" @click="toWactch(i.id,i.type)">可观看</div>
								</div>
								<div class="line1" v-if="index!=(item.child.length-1)"></div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
				<!-- 课程评价 -->
				<div v-show="this.chooseMenu==3">
					<div class="courseDetail-commentMenu">
						<el-rate class="rate ratet114" id="smqk" disabled-void-color="#cccccc" v-model="advRank" disabled show-score
						 text-color="#ff9900" score-template="{value}"></el-rate>
						<div class="courseDetail-colLine"></div>
						<el-radio-group v-model="radio" fill="#00D0B0" text-color="#333333" class="ratet114" @change="chooseComment()">
							<el-radio :label="0">全部评价</el-radio>
							<el-radio :label="1">好评</el-radio>
							<el-radio :label="2">差评</el-radio>
						</el-radio-group>
					</div>
					<div v-for="(item,index) in commentData">
						<div class="courseDetail-commentItem">
							<div class="courseDetail-itemLeft">
								<img :src="item.avatarurl" class="courseDetail-userImg">
								<el-rate class="rate rate1" v-model="item.rank" disabled text-color="#ff9900" score-template="{value}"></el-rate>
							</div>
							<div class="courseDetail-itemRight">
								<div>{{item.addtime}}</div>
								<div>{{item.nickname}}</div>
								<div>{{item.content}}</div>
								<div>已学习{{item.num}}个课</div>
							</div>
						</div>
						<div class="line1"></div>
					</div>
					<div class="pagination">
						<el-pagination @current-change="handleCurrentChange" background :current-page="currentPage" layout="prev, pager, next"
						 :total="total"></el-pagination>
					</div>
				</div>
			</div>
			<!-- 机构介绍 -->
			<div class="courseDetail-page-organize">
				<div class="courseDetail-organizeAvatra">
					<img :src="schoolData.schoolinfo.school_logo" class="courseDetail-organizeImg" @click="toOrganize()">
				</div>
				<div class="courseDetail-organizeName">{{schoolData.schoolinfo.school_name}}</div>
				<div class="courseDetail-organizeInfo">
					<div class="courseDetail-infoItem">
						<div>总体评分</div>
						<div>{{schoolData.schoolrank}}分</div>
					</div>
					<div class="courseDetail-infoItem mr">
						<div>课程数量</div>
						<div>{{schoolData.countgoods}}</div>
					</div>
					<div class="courseDetail-infoItem">
						<div>学员数量</div>
						<div>{{schoolData.countgoods}}</div>
					</div>
				</div>
				<div class="line"></div>
				<div class="courseDetail-organizeDetail">
					<div class="courseDetail-lineIcon"></div>
					<div class="courseDetail-detailText">{{schoolData.schoolinfo.school_info}}</div>
				</div>
			</div>
			<!-- 机构其他畅销课程 -->
			<div class="courseDetail-page-page-otherCourse">
				<div class="courseDetail-otherCourseTitle">机构其他畅销课程</div>
				<div :class="['courseDetail-otherCourseContent',Inv==0?'':'courseDetail-otherCourseContentIn']">
					<div v-for="item in schoolData.hotgoods" @click="getData(item.goods_id)">
						<div class="line" style="margin-bottom: 0;"></div>
						<div class="courseDetail-otherCourseItem">
							<div class="courseDetail-otherCourseAvatra">
								<img :src="item.goods_img" class="courseDetail-goodsImg">
								<img src="../../../assets/img/courseDetail/vipIcon.png" class="vipIcon">
							</div>
							<div class="courseDetail-otherCourseText">
								<div style="overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">{{item.goods_name}}</div>
								<div v-show="item.is_free==0">￥{{item.goods_price}}</div>
								<div v-show="item.is_free==1">免费</div>
							</div>
						</div>
					</div>
				</div>
				<div class="courseDetail-moreBtn" @click="showMore()" v-if="Inv==0">显示更多</div>
			</div>
		</div>
	</div>
</template>

<script>
	import QRCode from 'qrcodejs2'
	import 'video.js/dist/video-js.css'
	import {
		videoPlayer
	} from 'vue-video-player'
	import 'videojs-flash'
	import contact from '../../common/contact.vue';
	import totop from '../../common/totop.vue';
	export default {
		name: "qrCode",
		components: {
			contact,
			totop,
			videoPlayer,
		},
		data() {
			return {
				// 遮罩
				zhezhao: false,
				free: 1,
				// 课程评分
				value: 3.7,
				// 优惠券
				discount: false,
				couponData: {},
				schoolId: '',
				catClass: [],
				// 菜单
				chooseMenu: 1,
				menuList: [{
						id: 1,
						name: "课程简介"
					},
					{
						id: 2,
						name: "课程目录"
					},
					{
						id: 3,
						name: "课程评价"
					},
				],
				// 课程目录折叠面板
				activeNames: [1],
				// 单选按钮组
				radio: 0,
				Inv: 0,
				// 右上角分享按钮
				share: false,
				// 右上角收按钮
				is_collection: 0,
				collect: 0,
				// 获取到的id
				resId: 7,
				// 课程数据
				goodsData: {},
				// 机构数据
				schoolData: {},
				// 课程目录数据
				menuData: {},
				// 课程评价数据
				commentData: {},
				advRank: '',
				size: 0,
				// 分页
				list_rows: 1,
				page: 1,
				currentPage: 1,
				total: 0,
				// 该课程是否报名
				learn: false,
				// 用手机看按钮
				phone: false,
				// 判断用户类型
				type: 1,
				// 判断是否报名
				is_show: 0,
				// 播放视频的url
				url: '',
				// type为3时获取到的数据
				resData: {},

				qcode: 1,

				playerOptions: {
					playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: 'rtmp/mp4',
						src: 'rtmp://pili-live-rtmp.qdy2020.com/ruiyou/keith3?sign=55aa81380d6c079526a12f73571754ce&t=60756799'
					}],
					poster: "http://vjs.zencdn.net/v/oceans.png",
					techOrder: ['flash'],
					width: document.documentElement.clientWidth,
					notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
						timeDivider: true,
						durationDisplay: true,
						remainingTimeDisplay: false,
						fullscreenToggle: true //全屏按钮
					}
				},
				isroom: 1,
				isf12: false
			}
		},
		created() {
			this.getData(this.$route.query.id)
			this.getgoodscate()
			if (this.$route.query.isroom) {
				this.isroom = this.$route.query.isroom
			}
		},
		mounted() {
			addEventListener('keydown', this.keyDown) //创建监听键盘事件
		},
		beforeDestroy() {
			this.qcode = 1
		},
		watch: {
			'$route': function() {
				this.getData(this.$route.query.id)
				if (this.$route.query.isroom) {
					this.isroom = this.$route.query.isroom
				}
			},
		},
		methods: {
			// 判断是否报名成功（视频是否可播放）
			checkPay() {
				this.$post('goods/check', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.is_show = response.is_show
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			//键盘监听
			keyDown(e) {
				if (e.key == 'F12') {
					console.log("不好意思,教育视频是宝贵的资源，给你个播放两只老虎儿歌链接净化下心灵。lfs警告")
					this.newsrc()
					this.isf12 = true
					return false
				}
			},
			newsrc() {
				var video = document.getElementById('video');
				video.src = "https://www.littleducks.cn/uploads/zwegtyflash/liangzhilaohuerge.mp4"
			},
			getgoodscate() {
				var that = this
				this.$get('goodscate/getgoodscatetreelist', {}).then(response => {
					if (response.error == 0) {
						this.itemclass = response.data.goodsCateTreeList
						for (var i = 0; i < this.itemclass.length; i++) {
							if (this.itemclass[i].children.length == 0) {
								this.itemclass[i].children = [{
									cat_img: "https://ykt.qdy2020.com/image/icon3.png",
									cat_name: "暂没",
									children: [{
										cat_img: "https://ykt.qdy2020.com/image/icon3.png",
										cat_name: "暂没",
										children: [],
										id: this.itemclass[i].id,
										is_show: 1,
										level: 1,
										p_id: 25,
										sort: 0
									}],
									id: this.itemclass[i].id,
									is_show: 1,
									level: 1,
									p_id: 25,
									sort: 0
								}]
							}
						}
					} else {
						return
					}
				});
			},
			//点击头部跳转到分类
			tbchoose(e, index) {
				this.bchoose = e
				if (index == 0) {
					for (var i = 0; i < this.itemclass.length; i++) {
						if (e == this.itemclass[i].id) {
							this.$router.push({
								path: '/search',
								query: {
									id: i,
									choose: i,
									data: this.itemclass
								}
							})
						}
					}
				}
				if (index == 1) {
					for (var i = 0; i < this.itemclass.length; i++) {
						for (var j = 0; j < this.itemclass[i].children.length; j++) {
							if (e == this.itemclass[i].children[j].id) {
								this.$router.push({
									path: '/search',
									query: {
										id: j,
										choose: i,
										twochoose: j,
										data: this.itemclass
									}
								})
							}
						}
					}
				}
				if (index == 2) {
					for (var i = 0; i < this.itemclass.length; i++) {
						for (var j = 0; j < this.itemclass[i].children.length; j++) {
							for (var k = 0; k < this.itemclass[i].children[j].children.length; k++) {
								if (e == this.itemclass[i].children[j].children[k].id) {
									this.$router.push({
										path: '/search',
										query: {
											id: k,
											choose: i,
											twochoose: j,
											threechoose: k,
											data: this.itemclass
										}
									})
								}
							}
						}
					}
				}


			},
			//生成二维码
			creatQrCode() {
				if (this.qcode == 1) {
					var qrcode = new QRCode(this.$refs.qrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var qrcode = new QRCode(this.$refs.q1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var brcode = new QRCode(this.$refs.vrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var brcode = new QRCode(this.$refs.v1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var cqcode = new QRCode(this.$refs.wrCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var cqcode = new QRCode(this.$refs.w1rCodeUrl, {
						text: 'https://ykt.qdy2020.com/i/#/courseDetail?id=' + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var aqcode = new QRCode(this.$refs.qcodems, {
						text: 'https://ykt.qdy2020.com/a/123?classtype=1' + "&goods_id=" + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
					var aqcode = new QRCode(this.$refs.qcodemss, {
						text: 'https://ykt.qdy2020.com/a/123?classtype=1' + "&goods_id=" + this.$route.query.id, // 需要转换为二维码的内容
						width: 90,
						height: 91,
						colorDark: '#000000',
						colorLight: '#ffffff',
						correctLevel: QRCode.CorrectLevel.H
					})
				}
				this.qcode = 2
			},
			// 获取课程总数据
			getData(e) {
				this.resId = e
				this.isroom = 1
				scrollTo(0, 0);
				this.getMenuList()
				if (localStorage.getItem("token")) {
					this.checkPay()
					this.getCommentList()
					this.checkCollection()
				}
				this.$get('goods/index/' + e).then(response => {
					if (response.error == 0) {
						this.goodsData = response.data.goods
						this.schoolData = response.data.school;
						this.playerOptions.poster = response.data.goods.goods_img
						this.catClass = response.data.cat
						this.schoolId = this.schoolData.id
					} else {
						this.$router.push({
							path: "/home"
						})
						this.$message.error(response.msg);
					}
				});
			},

			// 课程目录接口
			getMenuList() {
				this.$post('goods/content/', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.menuData = response.data
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 课程评价接口
			getCommentList() {
				this.$post('goods/comment/?page=' + this.page, {
					goods_id: this.resId,
					type: this.radio,
					size: 10,
				}).then(response => {
					if (response.error == 0) {
						this.commentData = response.data.data
						this.total = response.data.total
						this.advRank = response.rank
						var mvn = document.getElementById("smqk")
						var ppp = document.getElementsByTagName("i")
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 获取优惠券列表
			getCoupon() {
				// 需要schoolid
				this.$get('goods/getcoupon/' + this.schoolId).then(response => {
					if (response.error == 0) {
						this.couponData = response.data;
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 领取优惠券
			toGetCoupon(which) {
				this.$post('goods/addcoupon/', {
					// school_id: this.schoolId,
					coupon_id: which
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						this.getCoupon()
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 优惠券按钮
			getDiscount() {
				if(!localStorage.getItem("token")){
					this.$message.error("请登录")
					return
				}
				this.getCoupon()
				if (this.discount == true) {
					this.discount = false
				} else {
					this.discount = true
				}
			},

			// 校验用户是否收藏此课程
			checkCollection() {
				this.$post('goods/checkcollection/', {
					goods_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.is_collection = response.is_collection
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 收藏按钮
			toCollect() {
				if(!localStorage.getItem("token")){
					this.$message.error("请登录")
					return
				}
				if (this.is_collection == 0) {
					this.$post('goods/collectgoods/', {
						goods_id: this.resId,
						status: 1
					}).then(response => {
						if (response.error == 0) {
							this.is_collection = 1
							this.$message.success(response.msg);
						} else {
							this.$message.error(response.msg);
						}
					});
				} else if (this.is_collection == 1) {
					this.$post('goods/collectgoods/', {
						goods_id: this.resId,
						status: 2
					}).then(response => {
						if (response.error == 0) {
							this.is_collection = 0
							this.$message.success(response.msg);
						} else {
							this.$message.error(response.msg);
						}
					});
				}
			},
			// 添加购物车
			toAdd() {
				if(!localStorage.getItem("token")){
					this.$message.error("请登录")
					return
				}
				this.$post('cart/addcart/', {
					goods_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg);
						localStorage.setItem("buycarcount", response.cart_count)
						this.$router.push({
							path: "courseDetail",
							query: {
								id: this.resId,
								name: localStorage.getItem("buycarcount"),
							}
						})
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 报名操作接口
			payAdd() {
				this.$post('order/buy', {
					goods_id: this.resId,
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
					} else {
						this.$message.error(response.msg);
					}
				});
			},

			// 报名按钮
			toLearn() {
				if (!localStorage.getItem('token')) {
					this.$message.error("请登录!")
					return
				}
				this.$post('order/buy', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
						// 判断是否免费
						if (this.type == 1) {
							this.zhezhao = true
						} else if (this.type == 2) {
							this.goodsData.is_free = 1
							this.type == 1
						} else if (this.type == 3) {
							this.resData = response.data
							this.$router.push({
								path: '/payConfirm',
								query: {
									id: this.resId,
									data: this.resData
								}
							})
						}
						this.checkPay()
						this.$message.success(response.msg);
					} else {
						this.$message.error(response.msg);
					}
				});
			},
			// 去学习
			toLearn1() {
				this.zhezhao = false
				this.is_show = 1
			},

			// 课程章节播放接口
			toWactch(id, type) {
				if (type == 1) {
					this.isroom = 2
				} else {
					this.isroom = 1
				}
				this.$post('order/buy', {
					goods_id: this.resId
				}).then(response => {
					if (response.error == 0) {
						this.type = response.type
						// 免费页面播放
						this.$post('goods/look/', {
							goods_id: this.resId,
							content_id: id
						}).then(response => {
							if (response.error == 0) {
								this.url = response.data.content.url
								if (this.is_show == 0) {
									this.$message.error("请先报名！");
								} else {
									if (this.type == 2 || this.type == 3) {
										this.goodsData.is_free = 1
									}
									if (this.isf12) {
										this.$message.error("请关闭调试模式后刷新页面后视频恢复正常播放")
										this.newsrc()
										return
									}
									this.$message.success("播放成功！");
									this.url = response.data.content.url
									var play_url = response.data.content.url;
									var video = document.getElementById('video');
									this.playerOptions.sources[0].src = response.data.content.url;
									if (type == 1) {
										this.playerOptions.poster = response.data.content.broadcast_img;
									} else {
										this.playerOptions.poster = response.data.content.video_img;
									}
									//对地址进行加密
									window.URL = window.URL || window.webkitURL;
									var xhr = new XMLHttpRequest();
									xhr.open("GET", play_url, true);
									xhr.responseType = "blob";
									xhr.onload = function() {
										if (this.status == 200) {
											var blob = this.response;
											video.onload = function(e) {
												window.URL.revokeObjectURL(video.src);
											};
											video.src = window.URL.createObjectURL(blob);
										}
									}
									xhr.send();
									//使用事件监听方式捕捉事件
									video.addEventListener("timeupdate", function() {
										var timeDisplay;
										var duration;
										timeDisplay = Math.floor(video.currentTime);
										duration = Math.floor(video.duration);
									}, false);

								}
							} else {
								this.$message.error(response.msg);
							}
						});
					} else {
						if (response.error == 40008) {
							this.$post('goods/look/', {
								goods_id: this.resId,
								content_id: id
							}).then(response => {
								if (response.error == 0) {
									this.url = response.data.content.url
									if (this.is_show == 0) {
										this.$message.error("请先报名！");
									} else {
										this.goodsData.is_free = 1
										if (this.isf12) {
											this.$message.error("请关闭调试模式后刷新页面后视频恢复正常播放")
											this.newsrc()
											return
										}
										this.$message.success("播放成功！");
										this.url = response.data.content.url
										var video = document.getElementById('video');
										//对地址进行加密
										window.URL = window.URL || window.webkitURL;
										var xhr = new XMLHttpRequest();
										var play_url = response.data.content.url;
										this.playerOptions.sources[0].src = response.data.content.url;
										if (type == 1) {
											this.playerOptions.poster = response.data.content.broadcast_img;
										} else {
											this.playerOptions.poster = response.data.content.video_img;
										}
										xhr.open("GET", play_url, true);
										xhr.responseType = "blob";
										xhr.onload = function() {
											if (this.status == 200) {
												var blob = this.response;
												video.onload = function(e) {
													window.URL.revokeObjectURL(video.src);
												};
												video.src = window.URL.createObjectURL(blob);
											}
										}
										xhr.send();
										//使用事件监听方式捕捉事件
										video.addEventListener("timeupdate", function() {
											var timeDisplay;
											var duration;
											timeDisplay = Math.floor(video.currentTime);
											duration = Math.floor(video.duration);
										}, false);
									}
								} else {
									this.$message.error(response.msg);
								}
							});
						} else {
							this.$message.error(response.msg);
						}
					}
				});
			},

			// 选择菜单
			courseMenu(which) {
				this.chooseMenu = which
			},

			// 选择评价类型
			chooseComment() {
				this.getCommentList()
			},

			// 试看
			preWatch() {
				this.chooseMenu = 2
			},

			// 向x轴右方向滚动
			toNextItem() {
				document.getElementById('scollItems').scrollLeft += 1000;
			},

			// 向x轴左方向滚动
			toAheadItem() {
				document.getElementById('scollItems').scrollLeft -= 1000;
			},

			// 课程目录折叠面板
			handleChange(val) {},

			// 跳转到机构主页
			toOrganize() {
				this.$router.push({
					path: '/organizeInfo',
					query: {
						id: this.schoolId
					}
				})
			},
			tohome() {
				this.$router.push({
					path: '/home',
					query: {
						id: this.schoolId
					}
				})
			},
			// 其他课程显示更多
			showMore() {
				if (this.Inv == 0) {
					this.Inv = 1
				}
			},
			// 分享按钮
			toShare() {
				if (this.share == false) {
					this.share = true
					this.creatQrCode()
				} else {
					this.share = false
				}
			},

			// 用手机看
			toPhone() {
				if (this.phone == false) {
					this.phone = true
					this.creatQrCode()
				} else {
					this.phone = false
				}
			},

			// 当前页的变化
			handleCurrentChange(e) {
				this.page = e
				this.getCommentList()
			},
			showchat() {
				this.$refs.child.toshow(this.schoolId)
			}
		},
	}
</script>

<style>
	.courseDetail-page-bread {
		position: relative;
		top: 33px;
		margin-bottom: 63px;
		margin-left: 12%;
		cursor: pointer;
	}

	.courseDetail-zhezhao {
		width: 100%;
		height: 3100px;
		opacity: 0.6;
		background: #000000;
		position: absolute;
		z-index: 2;
		margin-top: -100px;
	}

	.courseDetail-successBox {
		width: 460px;
		height: 300px;
		background: #FFFFFF;
		position: absolute;
		z-index: 3;
		margin-top: 150px;
		margin-left: 35%;
		text-align: center;
	}

	.courseDetail-boxTips {
		width: 140px;
		heigh: auto;
		margin: 0 auto;
		margin-top: 60px;
		border: 1px solid transparent;
	}

	.courseDetail-boxTips span {
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 35px;
		margin-left: 16px;
	}

	.courseDetail-boxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 38px;
	}

	.courseDetail-boxBtn {
		width: 355px;
		height: 68px;
		background: #00D0B0;
		border-radius: 10px;
		font-size: 20px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 68px;
		text-align: center;
		margin: 0 auto;
		margin-top: 50px;
	}

	.courseDetail-page-all {
		width: 74.5%;
		height: 280px;
		background-color: white;
		margin: 0 auto;
		padding: 20px 20px;
	}

	.courseDetail-page-content {
		width: 76.6%;
		min-height: 900px;
		height: auto;
		margin: 0 auto;
		margin-top: 20px;
	}

	.courseDetail-page-detail {
		width: 70%;
		height: 1170px;
		background-color: white;
		margin-right: 2.3%;
		float: left;
		padding-bottom: 60px;
		overflow: hidden;
		overflow-y: auto;
	}

	.courseDetail-page-organize {
		display: inline-block;
		width: 25.5%;
		height: 420px;
		background-color: white;
		padding: 30px 16px;
	}

	.courseDetail-page-page-otherCourse {
		display: inline-block;
		width: 27.6%;
		height: 730px;
		background-color: white;
		margin-top: 20px;
		overflow: hidden;
	}

	.courseDetail-page-page-coursePic {
		width: 470px;
		height: 280px;
		background-color: #000000;
		float: left;
		margin-right: 25px;
	}

	.courseDetail-page-page-rightTitle {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.courseDetail-titleRight {
		height: 32px;
		float: right;
		margin-top: -30px;
	}

	.courseDetail-titleRight img {
		cursor: pointer;
	}

	.courseDetail-shareBox {
		width: 342px;
		height: 260px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: 1100px;
		margin-top: 0px;
		display: flex;
		flex: 3;
		z-index: 200;
		flex-wrap: nowrap;
	}

	.courseDetail-shareBoxItem {
		width: 114px;
		height: 269px;
		text-align: center;
	}

	.sharecourseDetail-boxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
		margin-top: 25px;
		text-align: center;
	}

	.courseDetail-plateformIcon {
		margin-top: 36px;
		height: 51px;
		width: 51px;
	}

	.courseDetail-erweimaIcon {
		margin-top: 21px;
		height: 90px;
		width: 91px;
		margin-left: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.courseDetail-triangle {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: 1170px;
		margin-top: -5px;
	}

	.courseDetail-rightGrade {
		width: auto;
		height: 26px;
		margin-top: 20px;
	}

	.courseDetail-gradeText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 26px;
		margin-right: 20px;
	}

	.courseDetail-courseDetail-colLine1 {
		font-size: 14px;
		background: #E6E6E6;
		line-height: 26px;
	}

	.line {
		width: 100%;
		height: 1px;
		background-color: #E6E6E6;
		margin-bottom: 20px;
	}

	.line1 {
		width: 95%;
		height: 1px;
		background-color: #E6E6E6;
		margin: 0 auto;
		margin-bottom: 20px;
	}

	.rate {
		display: inline-block;
		margin-left: 20px;
		line-height: 12px;
		color: #CCCCCC;
	}

	.courseDetail-rightPrice {
		width: auto;
		height: 34px;
		margin: 20px 0;
	}

	.courseDetail-rightPrice span:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.courseDetail-rightPrice span:nth-child(2) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
	}

	.courseDetail-rightPrice span:nth-child(3) {
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-right: 20px;
	}

	.courseDetail-rightPrice span:nth-child(4) {
		font-size: 22px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-right: 20px;
	}

	.courseDetail-rightPrice span:nth-child(5) {
		display: inline-block;
		width: 80px;
		height: 30px;
		background: #E0BD59;
		border-radius: 5px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 30px;
		text-align: center;
	}

	.courseDetail-rightDiscount div:first-child {
		display: inline-block;
		width: 40px;
		height: 24px;
		border: 1px solid #56D2BF;
		border-radius: 5px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #56D2BF;
		line-height: 24px;
		text-align: center;
		margin-right: 8px;
	}

	.courseDetail-rightDiscount div:last-child {
		display: inline-block;
		width: 76px;
		height: 24px;
		border: 1px solid #F4586C;
		border-radius: 5px;
		font-size: 14px;
		cursor: pointer;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		line-height: 24px;
		text-align: center;
	}

	/* 优惠券窗口 */
	.courseDetail-organizeDiscount {
		width: 260px;
		height: 186px;
		background: #FFFFFF;
		border: 1px solid #CCCCCC;
		position: absolute;
		margin-left: 496px;
		z-index: 1;
		overflow-y: auto;
		text-align: left;
	}

	.courseDetail-discountTitle {
		width: 245px;
		height: 30px;
		line-height: 30px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		margin: 10px;
	}

	.courseDetail-discountContent {
		width: 212px;
		height: auto;
		margin: 10px;
	}

	.courseDetail-contentUp {
		width: 238px;
		height: 30px;
	}

	.courseDetail-contentUp span:nth-child(1) {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #F4586C;
	}

	.courseDetail-contentUp span:nth-child(2) {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.courseDetail-contentUp span:nth-child(3) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #F4586C;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F4586C;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.courseDetail-contentUp span:nth-child(4) {
		width: 46px;
		height: 20px;
		background: #FFFFFF;
		border: 1px solid #999999;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 20px;
		text-align: center;
		float: right;
	}

	.courseDetail-contentDown {
		font-size: 13px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.courseDetail-rightBtn1 {
		display: inline-block;
		width: 118px;
		height: 44px;
		background: #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		cursor: pointer;
		text-align: center;
		margin-top: 60px;
	}

	.courseDetail-rightBtn2 {
		display: inline-block;
		width: 118px;
		height: 44px;
		background: #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 44px;
		cursor: pointer;
		text-align: center;
		margin-top: 60px;
	}

	.courseDetail-rightBtn4none {
		width: 18px;
		display: inline-block;
		height: 44px;
		cursor: pointer;
	}

	.courseDetail-rightBtn4 {
		display: inline-block;
		width: 118px;
		height: 44px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		line-height: 44px;
		text-align: center;
		margin: 0 13px;
	}

	.courseDetail-rightBtn4:active {
		background-color: #00D0B0;
		color: white;
	}

	.courseDetail-courseDetail-rightBtn4 {
		display: inline-block;
		width: 118px;
		height: 44px;
		line-height: 44px;
		position: absolute;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 58px;
		color: #00D0B0;
		cursor: pointer;
	}

	.courseDetail-rightBtn4:active {
		background-color: #00D0B0;
		color: white;
	}

	.courseDetail-zhezhao3 {
		width: 118px;
		height: 44px;
		background: #000000;
		opacity: 0.1;
		position: absolute;
		z-index: 1;
		margin-top: -44px;
	}

	.courseDetail-detail-menu {
		width: 100%;
		height: 54px;
		background: #FFFFFF;
		display: flex;
		flex-wrap: nowrap;
	}

	.courseDetail-menuTitle {
		width: 150px;
		height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 54px;
		cursor: pointer;
		text-align: center;
	}

	.courseDetail-menuTitleIn {
		width: 150px;
		height: 54px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: white;
		background-color: #00D0B0;
		line-height: 54px;
		text-align: center;
	}

	.courseDetail-detailTitle {
		font-size: 18px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: bold;
		color: #333333;
		margin-left: 20px;
		margin-bottom: 20px;
	}

	.courseDetail-detailPic {
		width: 840px;
		height: 350px;
		margin-left: 20px;
		margin-bottom: 10px;
	}

	.courseDetail-detailIntro {
		width: 98%;
		height: auto;
		margin-left: 2%;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		line-height: 31px;
		margin-bottom: 30px;
	}

	.courseDetail-detaiTeacher {
		width: 100%;
		height: 370px;
		margin-bottom: 30px;
		overflow: hidden;
		display: flex;
		flex-wrap: nowrap;
		float: left;
		margin-top: -70px;
	}

	.courseDetail-aheadIcon {
		width: 40px;
		height: 40px;
	}

	.courseDetail-nextIcon {
		width: 40px;
		height: 40px;
	}

	.courseDetail-itemAvatra {
		width: 84px;
		height: 84px;
		border-radius: 50%;
		position: relative;
		margin-left: 70px;
		top: 80px;
		/* background-color: #000000; */
	}

	.courseDetail-detailAllItems {
		width: 100%;
		height: 400px;
		margin-bottom: 30px;
		overflow-x: auto;
		display: flex;
		flex-wrap: nowrap;
		float: left;
	}

	.courseDetail-teacherItem {
		width: 214px;
		height: 290px;
		margin-right: 34px;
		border: 1px solid transparent;
		margin-left: 20px;
	}

	.courseDetail-box-card {
		width: 214px;
		height: 232px;
		margin-top: 40px;
	}

	.teacherName {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #181818;
		margin: 0 auto;
		margin-top: 44px;
		margin-bottom: 13px;
		text-align: center;
	}

	.courseDetail-teacherIntro {
		width: 100%;
		height: 130px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 24px;
		overflow-y: auto;
	}

	.courseDetail-detailTabs {
		margin-top: 20px;
	}

	.courseDetail-tabsItem {
		width: 90%;
		height: 50px;
		cursor: pointer;
		margin-left: 60px;
	}

	.courseDetail-videoIcon {
		width: 30px;
		height: 40px;
		float: left;
		margin-top: 5px;
	}

	.courseDetail-tabsTitle {
		display: inline-block;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-right: 10px;
	}

	.courseDetail-tabsContent {
		display: inline-block;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.courseDetail-tabsBtn {
		width: 50px;
		height: 22px;
		border: 1px solid #00D0B0;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		cursor: pointer;
		line-height: 22px;
		text-align: center;
		float: right;
	}

	.courseDetail-commentMenu {
		width: 95%;
		height: 48px;
		background: #F6F6F6;
		display: flex;
		flex-wrap: nowrap;
		line-height: 48px;
		margin: 0 auto;
	}

	.courseDetail-commentItem {
		width: 90%;
		height: 100px;
		margin: 0 auto;
		margin-top: 20px;
	}

	.courseDetail-itemLeft {
		width: 120px;
		height: 90px;
		float: left;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-right: 20px;
	}

	.courseDetail-itemRight {
		width: 80%;
		height: 90px;
		display: inline-block;
	}

	.courseDetail-itemRight div:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #ACACAC;
		float: right;
	}

	.courseDetail-itemRight div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.courseDetail-itemRight div:nth-child(3) {
		width: 600px;
		height: 40px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		overflow: hidden;
		margin-top: 5px;
	}

	.courseDetail-itemRight div:nth-child(4) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.courseDetail-userAvatra {
		width: 100%;
		height: 50px;
		justify-content: center;
		display: flex;
		margin: 0 auto;
		margin-left: -10px;
	}

	.courseDetail-userImg {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.courseDetail-colLine {
		width: 1px;
		height: 46px;
		background: #E4E4E4;
		color: #E4E4E4;
		margin: 0 22px;
	}

	.courseDetail-organizeAvatra {
		width: 74px;
		height: 74px;
		border-radius: 15px;
		margin: 0 auto;
	}

	.courseDetail-organizeImg {
		width: 74px;
		height: 74px;
		border-radius: 15px;
		cursor: pointer;
	}

	.courseDetail-organizeName {
		width: 100%;
		height: 20px;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		text-align: center;
		margin-top: 18px;
	}

	.courseDetail-organizeInfo {
		width: 80%;
		height: 60px;
		margin: 0 auto;
		margin-top: 23px;
		margin-bottom: 18px;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.courseDetail-infoItem {
		width: auto;
		height: 60px;
		text-align: center;
	}

	.courseDetail-infoItem div:first-child {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #666666;
	}

	.courseDetail-infoItem div:last-child {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 15px;
	}

	.mr {
		margin: 0 40px;
	}

	.courseDetail-organizeDetail {
		width: 100%;
		height: 190px;
	}

	.courseDetail-lineIcon {
		width: 3px;
		height: 14px;
		background: #00D0B0;
		float: left;
		margin-right: 10px;
		margin-top: -1px;
	}

	.courseDetail-detailText {
		display: inline-block;
		width: 93%;
		height: 200px;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 24px;
		margin-top: -6px;
		overflow-y: auto;
	}

	.courseDetail-otherCourseTitle {
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-left: 20px;
		margin-top: 20px;
		margin-bottom: 15px;
	}

	.courseDetail-otherCourseContent {
		width: 100%;
		height: 605px;
		overflow: hidden;
	}

	.courseDetail-otherCourseContentIn {
		width: 110%;
		height: 655px;
		overflow-y: auto;
	}

	.courseDetail-otherCourseItem:hover {
		background: #F9F9F9;
	}

	.courseDetail-otherCourseItem {
		width: 100%-40px;
		height: 76px;
		background: white;
		border-radius: 2px;
		padding: 12px 20px;
		cursor: pointer;
	}

	.courseDetail-otherCourseAvatra {
		width: 76px;
		height: 76px;
		border-radius: 5px;
		background-color: #000000;
		float: left;
		margin-right: 10px;
	}

	.courseDetail-goodsImg {
		width: 76px;
		height: 76px;
		border-radius: 5px;
		float: left;
	}

	.vipIcon {
		width: 21px;
		height: 14px;
		position: relative;
		margin-left: 55px;
		top: -75px;
	}

	.courseDetail-otherCourseText {
		display: inline-block;
		width: 65%;
		height: auto;
	}

	.courseDetail-otherCourseText div:nth-child(1) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
		margin-top: 6px;
	}

	.courseDetail-otherCourseText div:nth-child(2) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-top: 13px;
	}

	.courseDetail-otherCourseText div:nth-child(3) {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #F83850;
		margin-top: 13px;
	}

	.courseDetail-moreBtn {
		width: 124px;
		height: 32px;
		border: 1px solid #E5E5E5;
		border-radius: 5px;
		font-size: 14px;
		cursor: pointer;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		line-height: 32px;
		text-align: center;
		margin: 0 auto;
		margin-top: 15px;
	}

	.pagination {
		width: 100%;
		height: auto;
		text-align: center;
	}

	/* 免费课程内容 */
	.courseDetail-page-all1 {
		width: 76.5%;
		height: 580px;
		background-color: white;
		margin: 0 auto;
		display: flex;
		flex: 2;
		flex-wrap: nowrap;
	}

	.courseDetail-allLeft {
		width: 70%;
		height: 580px;
		background: #333333;
	}

	.courseDetail-zhezhao4 {
		width: 53.5%;
		height: 490px;
		opacity: 0.6;
		background: #000000;
		position: absolute;
		z-index: 2;
	}

	.heigt600 {
		height: 580px !important;
	}

	.video-out {
		width: 100%;
		height: 494px;
	}

	.courseDetail-allLeftIntro {
		width: 50%;
		height: 60px;
		margin: 0 auto;
		margin-top: 12px;
		position: absolute;
		display: flex;
		margin-left: 30px;
		flex: 2;
		flex-wrap: nowrap;
		z-index: 200;
	}

	.courseDetail-introLeft {
		width: 650px;
		height: 55px;
	}

	.courseDetail-introLeft .title {
		font-size: 24px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
	}

	.courseDetail-introLeft .title span:last-child {
		color: #F83850;
		margin-left: 30px;
	}

	.courseDetail-infoPeople {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		cursor: pointer;
		line-height: 36px;
	}

	.rate {
		display: inline-block;
	}

	.icon {
		width: 12px;
		height: 12px;
		margin-right: 20px;
	}

	.courseDetail-introRight {
		width: 222px;
		height: 58px;
		background: #00D0B0;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF;
		margin-left: 108px;
		line-height: 58px;
		z-index: 200;
		text-align: center;
		cursor: pointer;
	}

	.courseDetail-zhezhao2 {
		width: 222px;
		height: 58px;
		background: #000000;
		opacity: 0.6;
		position: absolute;
		z-index: 1;
		margin-left: 108px;
		cursor: pointer;
		margin-top: -58px;
	}

	.courseDetail-allRight {
		width: 26%;
		height: 560px;
		overflow: hidden;
		margin-left: 2%;
		margin-top: 10px;
	}


	.courseDetail-allRightBox {
		width: 110%;
		height: 580px;
		overflow-y: auto;
	}

	.courseDetail-collapseItem {
		width: 92%;
		height: auto;
		overflow: hidden;
	}

	.courseDetail-tabsContent1 {
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.courseDetail-shareBox1 {
		width: 342px;
		height: 269px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: -808px;
		margin-top: 65px;
		display: flex;
		z-index: 300px;
		flex: 3;
		flex-wrap: nowrap;
	}

	.courseDetail-triangle1 {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: -650px;
		margin-top: 60px;
	}

	.courseDetail-phoneBox {
		width: 342px;
		height: 300px;
		background: #FFFFFF;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		position: absolute;
		margin-left: -704px;
		margin-top: 65px;
		text-align: center;
	}

	.phonecourseDetail-boxText {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
		margin-top: 30px;
	}

	.courseDetail-phoneBoxCenter {
		width: 90%;
		height: 140px;
		margin: 0 auto;
		margin-top: 20px;
		display: flex;
	}

	.courseDetail-phoneBoxItem {
		width: 150px;
		height: 140px;
	}

	.courseDetail-diviver {
		width: 1px;
		height: 120px;
		background-color: #E4E4E4;
	}

	.courseDetail-itemText {
		width: 80px;
		height: auto;
		margin: 0 auto;
		margin-top: 12px;
		display: flex;
		justify-content: center;
	}

	.courseDetail-itemText span {
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;
	}

	.courseDetail-phonecourseDetail-boxBtn {
		width: 150px;
		height: 40px;
		margin: 0 auto;
		font-size: 14px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		margin-top: 20px;
		line-height: 40px;
		text-align: center;
		background-color: #00D0B0;
		color: white;
		border-radius: 20px;
	}

	.courseDetail-triangle2 {
		width: 0;
		height: 0;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		border-bottom: 6px solid white;
		position: absolute;
		margin-left: -548px;
		margin-top: 60px;
	}

	i {
		margin-top: 0;
	}




	.courseDetail-buyCarIcon {
		width: 18px;
		height: 15px;
		margin-right: 5px;
		margin-top: 0px;
	}

	.courseDetail-toallstart {
		width: 52%;
		position: absolute;
		margin-top: 230px;
		display: flex;
		margin-left: 3px;
		justify-content: space-between;
	}

	.course-goodname {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 700px;
	}

	.rate1 {
		margin-top: 14px;
		margin-left: 0;
	}

	.ratet114 {
		margin-top: 14px;
	}

	.ratemr14 {
		margin-right: 34px;
	}


	/* 补回来的样式 */
	.styleone {
		margin: 0 15px;
	}

	.styletwo {
		width: 17px;
		height: 17px;
	}

	.stylethree {
		margin-right: 4px;
	}

	.stylefour {
		width: 12px;
		height: 12px;
		margin-top: 3px;
	}

	.info img {
		cursor: pointer;
	}

	.courseDetail-erweimaIcon img {
		height: 90px !important;
		width: 91px !important;
	}

	.courseDetail-videoIcon img {
		width: 15px;
		height: 15px;
	}

	.wxchattt {
		margin-left: 30px;
		margin-top: 10px;
	}
</style>
